import { Component,ViewEncapsulation } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';
import { auth } from 'firebase/app';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AngularFirestore } from '@angular/fire/firestore';

import { Router } from '@angular/router';//rout resolver 
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { HomePage } from '../app/home/home.page'



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public userkey:string
  public platformname: string = ''
  public rapopf:string = "web"
  constructor(
    public navCtrl: NavController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    public firebasex: FirebaseX,    
    public afs: AngularFirestore,
    public screenOrientation: ScreenOrientation,
    protected deeplinks: Deeplinks
    
  ) {
    this.userkey ="",
    this.initializeApp();
  }

  initializeApp() {
  var mythis = this
  
  this.platform.ready().then(() => {
   if (this.platform.is('cordova')) {
    console.log("subscribing dynamic links") 
    this.deeplinks.routeWithNavController(this.navCtrl, {
      '/': HomePage,
        
    }).subscribe((match) => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log('Successfully matched route', match.$link);
      console.log('Successfully matched route', match.$args);
      //alert(match.$link.url)
      if(match.$link.url.indexOf("/reach.") > -1 || match.$link.url.indexOf("/support.") > -1 || match.$link.url.indexOf("/channel.") > -1 || match.$link.url.indexOf("/topics.") > -1 || match.$link.url.indexOf("/board.") > -1){
        try{
          (<any>window).deeplinkOrgs(match.$link.url)              
        }
        catch(e){
          //alert(e)
          window.localStorage.setItem("deeplinkorgs",match.$link.url)
        }
      } else {
        if(match.$link.queryString){
            console.log(match.$link.url)
            if(match.$link.queryString.startsWith("apiKey=")){
              var loginemail = window.localStorage.getItem("loginemail")
              this.authService.signInWithEmailLink(loginemail,match.$link.url).then((res) => {
                this.router.navigateByUrl('/home');
              }, (err) => {
                console.log(err)
              });
            }
            else{            
              try{
                (<any>window).deeplinkOrgs(match.$link.queryString)              
              }
              catch(e){
                console.log('deeplinkOrgs not found')
                window.localStorage.setItem("deeplinkorgs",match.$link.queryString);
                console.log(e)
              }
            }
        }
      }
    },
    (nomatch) => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });   
   }
    this.createChannels()
    this.statusBar.overlaysWebView(false);
    this.statusBar.styleLightContent();
     this.afAuth.user.subscribe(user => {
      if (this.platform.is('cordova')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)
        if (this.platform.is('android')) {
          this.rapopf = "android"
        }
        if (this.platform.is('ios')) {
          this.rapopf = "ios"
        }
      } else {
        this.rapopf = "web"
      }
      window.localStorage.setItem("platform", this.rapopf)
      console.log(this.router.url)
      //alert(this.router.url)
      var routerurl = window.localStorage.getItem("locurl")
      var lm_joinkey = window.localStorage.getItem("joinkey")
      console.log(routerurl)
      if(lm_joinkey !== null){
        window.localStorage.setItem("joinkey",lm_joinkey) 
      }
      if(routerurl === null){
        window.localStorage.setItem("locurl",this.router.url) 
      } else if(routerurl.indexOf("?") == -1){
        window.localStorage.setItem("locurl",this.router.url) 
      }
      
        if(user){
          if(this.router.url.startsWith("/business-join")){
            //alert(this.router.url)
            window.localStorage.setItem("joinkey",this.router.url)
            location.href = "/home"
          }
          if(this.router.url.startsWith("/demo") || this.router.url.startsWith("/vc") || this.router.url.startsWith("/_blank") || this.router.url.startsWith("/usehere")){

          } else{
            
            
            console.log(user.uid)
            this.userkey = user.uid
            this.firebasex.getToken().then(token => {console.log(token);this.updateToken(token,"a")}) 
            this.router.navigate(["/home"]);
            /*if (this.platform.is('android') || this.platform.is('ios')) { 
              this.router.navigate(["/mobile"]);  
            }
            else{        
              this.router.navigate(["/home"]);
            }*/
          }
        } else {
          console.log(this.router.url)
          console.log(this.router.url.startsWith("/_blank"))
          if(this.router.url.startsWith("/welcome?apiKey=")){
            var loginemail = window.localStorage.getItem("loginemail")
            this.authService.signInWithEmailLink(loginemail,this.router.url).then((res) => {
              this.router.navigateByUrl('/home');
            }, (err) => {
              console.log(err)
              this.router.navigate(["/login"]);
            });
          }
          else if(this.router.url.startsWith("/vc") || this.router.url.startsWith("/_blank")){

          }          
          else{
            if(this.router.url.startsWith("/business-join")){
              window.localStorage.setItem("joinkey",this.router.url) 
            }
            this.router.navigate(["/login"]);
            
          }
        }
      }, err => {
        if(this.router.url.startsWith("/vc") || this.router.url.startsWith("/_blank")){

        }
        else{
          this.router.navigate(["/login"]);
        }
      }, () => {
        this.splashScreen.hide();
      })
      this.splashScreen.hide();
      this.firebasex.getToken().then(token => console.log(`The token is ${token}`))
      this.firebasex.onMessageReceived().subscribe(data => console.log(`FCM message: ${data}`));      
    })
    
      
  }

  createChannels(){
    var channel = {
      id: "Rapo_call_channel",
      description: "Rapo Call Channel",
      name: "Rapo Call",
      sound: "callringingtone",
      vibration: true,
      light: true,
      lightColor: "0xFF0000FF",
      importance: 4,
      badge: true,
      visibility: 1
    };
    if (this.platform.is('cordova')) {
    (<any>window).FirebasePlugin.setDefaultChannel(channel,
      function(){
          console.log('Default channel set');
      },
      function(error){
         console.log('Set default channel error: ' + error);
      });
    (<any>window).FirebasePlugin.createChannel(channel,
      function(){
          console.log('Channel created: ' + channel.id);
      },
      function(error){
         console.log('Create channel error: ' + error);
      });
    }
  }

  testing123(){
    alert("ok")
  }

  updateToken(token,os){
    console.log(token)
    if(this.userkey != ""){
      var val = {}
      val["token_"+os] = token
      console.log("updating to firestore")
      this.afs.collection("user_details").doc(this.userkey).update(val)
    }

    

  }

  logout(){
    this.authService.doLogout()
    .then(res => {
      this.navCtrl.pop();
    }, err => {
      console.log(err);
    })
  }
    /*this.platform.ready().then(() => {
      (<any>window).FirebasePlugin.getToken(function(token) {
        // save this server-side and use it to push notifications to this device
        if(token){
          mythis.router.navigate(["/home"]);
        }
        else{
          mythis.router.navigate(["/login"]);
        }
    }, function(error) {
        console.error(error);
    });
      this.afAuth.user.subscribe(user => {
        console.log(user)
        if(user){
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/login"]);
        }
      }, err => {
        this.router.navigate(["/login"]);
      }, () => {
        this.splashScreen.hide();
      })
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
   }
  });

  logout(){
    this.authService.doLogout()
    .then(res => {
      this.navCtrl.pop();
    }, err => {
      console.log(err);
    })
  }*/

  
}
