// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //googleWebClientId: '671555538891-1lato1vgtfitkfgid0g6kn7smq52j08l.apps.googleusercontent.com',
  googleWebClientId: '820574312382-pek19fkdn2ve2rto4kj8j71dcdmbnn92.apps.googleusercontent.com',//BRPGjaVyOyxqhm6XprCo6vAd
  //googleWebClientId: '820574312382-7pieab6168hd563ebbdloo9l9j1tafum.apps.googleusercontent.com',
  /*firebase : {
    apiKey: "AIzaSyDNrSFk0-sE81opgM-bnk65XldIWiz16NI",
    authDomain: "ionic-5420f.firebaseapp.com",//"console.rapo.me",
    databaseURL: "https://ionic-5420f.firebaseio.com",
    projectId: "ionic-5420f",
    storageBucket: "ionic-5420f.firebaseapp.com",
    messagingSenderId: "820574312382"
  }*/
  firebase : {
    apiKey: "AIzaSyCN0tiWCaguuQMu62jruS-WX0_QJuMUeYQ",
   authDomain: "rapo-prod.firebaseapp.com",//"console.rapo.me",
   databaseURL: "https://rapo-prod.firebaseio.com",
   projectId: "rapo-prod",
   storageBucket: "rapo-prod.appspot.com",
   messagingSenderId: "820574312382"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
