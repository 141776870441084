import { NgModule } from '@angular/core';
import { Platform } from '@ionic/angular';
import { PreloadAllModules, RouterModule, Routes, Router } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'prefix'
  },
  {
    path: 'business-join/:joinkey',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'login',
    loadChildren: './login/login.module#LoginPageModule' },  
  { path: 'register', 
    loadChildren: './register/register.module#RegisterPageModule' 
  },
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  /*{
    path: 'vc/:ukey/:conkey/:action',
    loadChildren: () => import('./webrtc/webrtc.module').then( m => m.WebrtcPageModule)
  },*/
  {
    path: '_blank',
    loadChildren: () => import('./blank/blank.module').then( m => m.BlankPageModule)
  },
  {
    path: 'usehere',
    loadChildren: () => import('./usehere/usehere.module').then( m => m.UseherePageModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then( m => m.DemoPageModule)
  },
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then( m => m.MobilePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor(
    private platform: Platform,
    public router: Router
  ) { this.initializeApp(); }

  initializeApp() {
    this.platform.ready().then(() => {
      console.log(this.router.url)
    });
  }
}
