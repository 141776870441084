import { Component,ViewEncapsulation,OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx'; 

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html?v=0.362',
  encapsulation: ViewEncapsulation.None
})

export class HomePage implements OnInit{
  public loaded: boolean = false
  public tempType: string = ''
  public isNewLogin: boolean = false
  public isweb:boolean = true
  public ismobile:boolean = false
  public isapp:boolean = false
  

  constructor(
    private platform: Platform,
    public diagnostic : Diagnostic
  ) { }
  
  ngOnInit() {
    console.log("loading")
    console.log(this.loaded)
    if (this.platform.is('android') || this.platform.is('ios')) { 
      this.isweb = false
      this.ismobile = true  
    }
    if (this.platform.is('cordova')) { 
      this.isapp = true
    }
    //this.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.1.0/jquery.min.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-app.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-auth.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-database.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-firestore.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-functions.js');
    this.loadScript('https://www.gstatic.com/firebasejs/7.1.0/firebase-storage.js');
    this.loadScript('https://cdn.firebase.com/libs/firebaseui/3.1.1/firebaseui.js');
    //this.loadScript('https://code.jquery.com/jquery-2.2.4.js');
    this.loadScript('assets/js-web/jquery.js?v=0.1');
    //this.loadScript('//code.jquery.com/ui/1.12.1/jquery-ui.js');
    //this.loadScript('assets/js/home.js?1.609');
    //this.loadScript('http://code.jquery.com/mobile/1.4.5/jquery.mobile-1.4.5.min.js');
    
    //this.loadScript('assets/js-web/swipe-page.js');

    this.loadScript('https://unpkg.com/axios/dist/axios.min.js');
    this.loadScript('assets/js-web/messages.js?v=7.36');
    this.loadScript('assets/js-web/bootstrap.min.js');
    this.loadScript('assets/js-web/myfirestore.js?v=0.1407');
    this.loadScript('assets/js-web/jquery.idle.min.js?v=0.1');
    this.loadScript('assets/js-web/app.js?v=0.473');
    
    //this.loadScript('assets/js-web/contacts.js?v=77.0');
    //this.loadScript('assets/js-web/browser.js?2.0');
    this.loadScript('assets/js-web/main.js?v=1.8448');
    this.loadScript('assets/js/fbmain.js?1.704');
    //this.loadScript('assets/js-web/voice.js');
    this.loadScript('assets/js-web/facebookpost.js');
    this.loadScript('assets/js/firestore_auth.js?2.1');
    this.loadScript('assets/js/adapter/adapter.js');
    this.loadScript('assets/js-web/facebook.js?3.1');
    this.loadScript('assets/js-web/ag-grid.js?1.401');
    this.loadScript('assets/js-web/ag-grid-enterprise.min.js?1.40');
    this.loadScript('assets/js/sheets.js?5.61');
    //this.loadScript('assets/js-web/items-table.js?v=0.4');
    this.loadScript('https://apis.google.com/js/client.js');
    this.loadAPIScript('https://apis.google.com/js/api.js');
    this.loadInScript('qrscanner');
    //this.loadScript('/assets/js/voicecall-inline.js?3');
    //this.loadScript('assets/js-web/googleContacts_old.js?v=137');
    this.loaded = true
    if (this.platform.is('cordova')) {
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.grantPermission();
      }
    }
    
    /*this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => console.log('Has permission?', result.hasPermission),
      err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    );

    this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA]);*/
    /*this.androidPermissions.requestPermissions([
      this.androidPermissions.PERMISSION.CAMERA, 
      this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS,
      this.androidPermissions.PERMISSION.RECORD_AUDIO
    ]);*/
  }

  grantPermission() {
    /**
     * NOTE:
     * 1. Check if location permission is already granted and enabled for app to use
     * 2. If not, show alert requesting location permission from user.
     */
  
  
     /*this.diagnostic.isMicrophoneAuthorized()
     .then(authorized => {
       console.log('Microphone is ' + (authorized ? 'authorized' : 'unauthorized'));
       if (!authorized) {
         this.diagnostic.requestMicrophoneAuthorization().then((status) => {
             switch (status) {
                 case this.diagnostic.permissionStatus.NOT_REQUESTED:
                     console.log('Permission not requested');
                     break;
                 case this.diagnostic.permissionStatus.GRANTED:
                     console.log('Microphone use is granted');
                     break;
                 case this.diagnostic.permissionStatus.DENIED:
                     console.log('Permission denied');
                     break;
                 case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                     console.log('Permission permanently denied');
                     break;
             }
         }).catch(error => {
           console.log(error);
         });
       }
     }).catch(err => {
       console.log(err);
     });*/
     this.diagnostic.isCameraAuthorized()
     .then(authorized => {
       console.log('Camera is ' + (authorized ? 'authorized' : 'unauthorized'));
       if (!authorized) {
         this.diagnostic.requestCameraAuthorization().then((status) => {
             switch (status) {
                 case this.diagnostic.permissionStatus.NOT_REQUESTED:
                     console.log('Permission not requested');
                     break;
                 case this.diagnostic.permissionStatus.GRANTED:
                     console.log('Camera use is granted');
                     break;
                 case this.diagnostic.permissionStatus.DENIED:
                     console.log('Permission denied');
                     break;
                 case this.diagnostic.permissionStatus.DENIED_ALWAYS:
                     console.log('Permission permanently denied');
                     break;
             }
         }).catch(error => {
           console.log(error);
         });
       }
     }).catch(err => {
       console.log(err);
     });
   }


  public loadScript(url: string) {
    var needtoload:boolean = true
    var screlements = document.body.getElementsByTagName("script")
    for(var x=0;x<screlements.length;x++){
      var screlement =screlements[x]
      if(screlement["src"] == url){
        needtoload = false
      }
    }
    if(needtoload){
      const body = document.body.getElementsByTagName("app-root")[0]
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      body.appendChild(script);
    }
  }

  public loadAPIScript(url: string) {
    var needtoload:boolean = true
    var screlements = document.body.getElementsByTagName("script")
    for(var x=0;x<screlements.length;x++){
      var screlement =screlements[x]
      if(screlement["src"] == url){
        needtoload = false
      }
    }
    if(needtoload){
      const body = document.body.getElementsByTagName("app-root")[0]
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = url;
      script.async = false;
      script.defer = true;
      //script.onload = "this.onload=function(){};handleClientLoad()";
      //script.onreadystatechange = "if (this.readyState === 'complete') this.onload()";
      body.appendChild(script);
    }
  }

  public loadInScript(scripttype: string) {
  var needtoload:boolean = true
  var scriptelements = document.body.getElementsByTagName("script")
  for(var x=0;x<scriptelements.length;x++){
    var scriptelement =scriptelements[x]
    if(scriptelement["id"] == scripttype){
      needtoload = false
    }
  }
  if(needtoload && document.getElementById('rapo_web')){
    const body = document.body.getElementsByTagName("app-root")[0]
    const script = document.createElement('script');
    script.innerHTML = "import QrScanner from '/assets/js-web/qr-scanner.min.js'; QrScanner.WORKER_PATH = '/assets/js-web/qr-scanner-worker.min.js'; var scanner ;document.getElementById('rapo_web').addEventListener('click', event => { const videoobj = document.getElementById('qr-video'); QrScanner.hasCamera().then(hasCamera => console.log(hasCamera)); scanner = new QrScanner(videoobj, result => loginQrResult(result)); scanner.start();	  }); document.getElementById('rapo_scan_connect').addEventListener('click', event => { const videoobj = document.getElementById('qr-video');	QrScanner.hasCamera().then(hasCamera => console.log(hasCamera)); scanner = new QrScanner(videoobj, result => connectQrResult(result));   scanner.start();	 });  document.getElementById('qrcodeScan_x').addEventListener('click', event => {   scanner.destroy();   scanner = null;   });";
    script.type = 'module';
    script.id = 'qrscanner';
    script.async = false;
    body.appendChild(script);
  }
}
  
  
}
